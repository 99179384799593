<template>
  <div>
    <Navbar />
    <main>
      <div class="info-ctn">
        <div class="heading">
          <h2>Welcome to AcadaPower Live</h2>
          <p>
            We have redesigned our live classes and meetings service, AcadaPower
            Live, to be free and accessible to everyone.
          </p>
        </div>
        <div class="info">
          <!-- <button class="start-btn">
            <i class="ri-add-box-line"></i>
            <span>New meeting</span>
          </button> -->
          <div class="input-box">
            <input
              type="text"
              placeholder="Enter a code or link"
              v-model="code"
              @keyup.enter="previewMeeting"
            />
            <button
              class="start-btn"
              :class="{ complete: this.code }"
              @click="previewMeeting"
            >
              join
            </button>
          </div>
        </div>
      </div>

      <div class="meetings-list">
        <h3>Upcoming Meetings</h3>
        <div class="loadingBig" v-if="loading">
          <span></span>
        </div>
        <div class="meetings" v-if="meetings.length > 0">
          <div class="meeting" v-for="meeting in meetings" :key="meeting.name">
            <div class="meeting-info">
              <h4>{{ meeting.title }}</h4>
              <p>
                {{ formatDate(meeting.start_date) }}
                {{ formatTime(meeting.start_date) }}
              </p>
              <p>
                {{ formatDate(meeting.end_date) }}
                {{ formatTime(meeting.end_date) }}
              </p>
            </div>
            <div class="meeting-link">
              <RouterLink
                :to="`/${$route.params.code}/${meeting.meeting_id}/preview`"
                >Join</RouterLink
              >
            </div>
          </div>
        </div>
        <div class="no-meetings" v-if="meetings.length === 0 && !loading">
          <img src="@/assets/img/no-meeting.svg" alt="" />
          <p>No scheduled live meetings</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import axios from "axios";
export default {
  name: "Home",
  components: {
    Navbar,
  },

  data() {
    return {
      code: "",
      meetings: [],
      loading: true,
    };
  },

  mounted() {
    this.getMeetings();
  },

  methods: {
    formatTime(timestamp) {
      const date = new Date(timestamp);
      const dateString = date.toLocaleDateString();
      const params = dateString.split("/");
      if (params[0].length == 1) params[0] = "0" + params[0];
      if (params[1].length == 1) params[1] = "0" + params[1];
      return params[0] + "/" + params[1] + "/" + params[2];
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      const timeString = date.toLocaleTimeString("it-IT");
      const params = timeString.split(":");
      if (params[0].length == 1) params[0] = "0" + params[0];
      if (params[1].length == 1) params[1] = "0" + params[1];
      if (Number(params[0]) > 12) {
        params[0] = Number(params[0]) - 12;
        return params[0] + ":" + params[1] + "pm";
      } else if (Number(params[0]) === 12) {
        return params[0] + ":" + params[1] + "pm";
      } else {
        return params[0] + ":" + params[1] + "am";
      }
    },

    previewMeeting() {
      if (this.code) {
        this.$router.push({
          name: "preview",
          params: { code: this.$route.params.code, meet: this.code },
        });
      }
    },

    async getMeetings() {
      let response;
      const headers = {
        "x-api-key": this.$cookies.get("api_key"),
      };
      try {
        response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/user/meeting/list`,
          {
            headers,
          }
        );
        if (response) {
          this.meetings = response.data.data.meetings.docs;
          this.loading = false;
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
        console.log(e.response);
        if (e.message.includes("Network")) {
          this.$toast.error("Please check your internet connection", {
            position: "top",
            duration: 3000,
          });
        } else {
          this.$toast.error(e.response.data.message, {
            position: "top",
            duration: 3000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 2em 2em;
  transition: 0.5s;

  @media (min-width: 1530px) {
    padding: 2em 0;
  }

  .loadingBig {
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    span {
      margin: 0 auto;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 3px solid $color-primary-light;
      border-top: 3px solid $color-primary;
      transition-property: transform;
      animation: spin 1s linear infinite;
    }
  }

  .info-ctn {
    width: 100%;
    .heading {
      margin-bottom: 2em;
      h2 {
        line-height: 45.5px;
        font-size: 32px;
        margin-bottom: 0.5em;
        font-weight: 400;
        color: $color-primary;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .info {
      display: flex;
      gap: 1em;

      .start-btn {
        display: flex;
        min-width: 8.5rem;
        align-items: center;
        justify-content: center;
        gap: 0.5em;
        padding: 0.75em 1em;
        border-radius: 5px;
        background-color: $color-grey-1;
        color: #fff;
        font-weight: 500;
        line-height: 21px;
        transition: 0.5s;
        font-size: 0.9rem;
        border: none;
        cursor: pointer;
        outline: none;

        &.complete {
          background-color: $color-primary;

          &:hover {
            background-color: darken($color-primary, 10%);
          }
        }
      }

      .input-box {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5em;

        @media (min-width: 960px) {
          width: 80%;
        }

        input {
          width: 100%;
          padding: 0.75em 1em;
          border-radius: 5px;
          border: 1px solid $color-grey-1;
          outline: none;
          font-size: 0.9rem;
          line-height: 21px;
          transition: 0.5s;

          &:focus {
            border: 1px solid $color-primary;
          }
        }

        // button {
        //   padding: 0.75em 1em;
        //   border-radius: 5px;
        //   background-color: transparent;
        //   color: $color-primary;
        //   font-weight: 500;
        //   line-height: 21px;
        //   transition: 0.5s;
        //   font-size: 0.9rem;
        //   border: none;
        //   cursor: pointer;
        //   outline: none;
        //   text-transform: capitalize;
        // }
      }
    }
  }

  .meetings-list {
    width: 100%;

    @media (min-width: 60em) {
      width: 50%;
    }

    h3 {
      font-weight: 500;
      font-size: 1.45rem;
      line-height: 24px;
      margin-bottom: 2rem;
    }

    .meetings {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .meeting {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1em;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid $color-grey-1;

        &:hover {
          border-bottom: 2px solid $color-grey-1;
        }

        .meeting-info {
          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0.5em;
            text-transform: capitalize;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $color-grey-1;
          }
        }

        .meeting-link {
          a {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $color-primary;
            text-decoration: none;
            transition: 0.5s;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .no-meetings {
      min-height: 40vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1em;

      img {
        width: 100%;
        max-width: 20rem;
      }

      p {
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

@media (min-width: 60em) {
  main {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    .info-ctn,
    .meetings-list {
      width: 50%;
    }

    .info-ctn {
      display: flex;
      flex-direction: column;
      max-width: 35rem;
      flex-basis: 35rem;

      .heading {
        h2 {
          font-size: 4rem;
          line-height: 60px;
        }

        p {
          font-size: 1.2rem;
          line-height: 27px;
        }
      }
    }

    .meetings-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 0.2rem;
      width: 50%;
      height: 80vh;
      h3 {
        font-size: 25px;
        font-weight: 500;
        line-height: 27px;
      }

      .meetings {
        overflow-y: scroll;
        .meeting {
          padding: 1.5em;
        }
      }
    }
  }
}
</style>
