import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { useCookies } from "vue3-cookies";
import { useToast } from "vue-toast-notification";
import axios from "axios";

const routes = [
  {
    path: "/home/:code",
    name: "home",
    component: Home,
    meta: { requiresAuth: true, title: "Home" },
  },
  {
    path: "/login/:code",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: { title: "Login" },
  },

  {
    path: "/:code/:meet",
    name: "meet",
    component: () => import("../views/Meeting.vue"),
    meta: { requiresAuth: true, title: "Meet" },
    children: [
      {
        path: "preview",
        name: "preview",
        component: () => import("../components/Meet/Preview.vue"),
        meta: { title: "Preview" },
      },
      {
        path: "meeting",
        name: "meeting",
        component: () => import("../components/Meet/Meeting.vue"),
        meta: { title: "Meeting" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const getSchoolByCode = async (code) => {
  let response;
  try {
    response = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/user/school/code?code=${code}`
    );

    if (response) {
      let school = response.data.data.school;
      useCookies().cookies.set("school", school, -1);
    }
  } catch (e) {
    console.log(e);
    console.log(e.response);
    if (e.message.includes("Network")) {
      useToast().error("Please check your internet connection", {
        position: "top",
        duration: 3000,
      });
    } else {
      console.log(e.response);
      useToast().error(e.response.data.message, {
        position: "top",
        duration: 3000,
      });
    }
  }
};

router.beforeEach(async (to, from, next) => {
  const school = useCookies().cookies.get("school");
  if (!school) {
    await getSchoolByCode(to.params.code);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // The route requires authentication
    document.title = to.meta.title;
    const api_key = useCookies().cookies.get("api_key");
    if (api_key) {
      // User is authenticated, proceed to the next route
      if (school) {
        let code = school.code;
        if (to.params.code !== code) {
          next({
            name: "login",
            params: { code: code },
          });
        } else {
          next();
        }
      }
    } else {
      // User is not authenticated, redirect to the login page
      next({
        name: "login",
        params: { code: to.params.code },
      });
    }
  } else {
    // The route does not require authentication, proceed to the next route
    next();
  }
});

export default router;
