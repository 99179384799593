import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/index.scss";
import "remixicon/fonts/remixicon.css";
import VueCookies from "vue3-cookies";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import VueSocketIO from "vue-socket.io";
import { io } from "socket.io-client";
import { useCookies } from "vue3-cookies";

const apiKey = useCookies().cookies.get("api_key");

const options = {
  transports: ["websocket"],
  path: "/socket.io",
  query: { api_key: apiKey },
  autoConnect: false,
};

const socket = new VueSocketIO({
  debug: false,
  connection: io(process.env.VUE_APP_SOCKET_URL, options),
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_",
  },
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueCookies)
  .use(VueToast)
  .use(socket)
  .mount("#app");
