<template>
  <nav>
    <div class="nav-wrapper">
      <router-link
        :to="`/home/${$cookies.get('school').code}`"
        class="brand-logo"
      >
        <i class="ri-live-fill logo"></i>
        <h4>AcadaPower Live</h4>
      </router-link>
      <ul>
        <li class="time-date">
          <div class="inner">
            <span class="time">
              {{
                new Date().toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </span>
            <span class="dot">.</span>
            <span class="date">
              {{
                new Date().toLocaleDateString([], {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                })
              }}
            </span>
          </div>

          <!-- <span><i class="ri-settings-3-line"></i></span> -->
        </li>
        <li>
          <span class="profile">
            <img
              v-if="$cookies.get('user').image"
              :src="$cookies.get('user').image"
              alt=""
            />
            <span v-else>
              {{
                $cookies.get("user").first_name[0] +
                $cookies.get("user").last_name[0]
              }}
            </span>
          </span>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style lang="scss" scoped>
nav {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 70px;
    .brand-logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $color-primary;
      font-weight: 500;
      font-size: 1.2rem;
      .logo {
        font-size: 2rem;
        margin-right: 10px;
      }

      h4 {
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
    }
    ul {
      display: flex;
      align-items: center;
      list-style: none;
      li {
        margin-left: 20px;

        @media screen and (max-width: 500px) {
          margin-left: 10px;
        }
        &.time-date {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 0.9rem;
          font-weight: 500;
          color: #000;

          .inner {
            display: flex;
            align-items: center;
            gap: 0.2rem;
          }

          i {
            font-size: 1.2rem;
            color: $color-primary;
            cursor: pointer;
          }
        }
        .profile {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: $color-primary-light;
          color: #fff;
          font-size: 1.2rem;
          font-weight: 500;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
